"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HOUR24 = exports.HOUR12 = exports.FROM_POPUP = exports.NotificationFilterSeverity = exports.NotificationState = exports.NotificationFilterState = exports.NotificationType = exports.SIGNALR_ENDPOINT = exports.UNSUBSCRIBE_SIGNALR = exports.RECEIVED_MESSAGE = exports.SUBSCRIBE_SIGNALR = exports.GLOBAL_USER_SETTINGS = exports.UNREAD_COUNT_ENDPOINT = exports.DELETE_MESSAGE_ENDPOINT = exports.SEND_MESSAGE_ENDPOINT = exports.UPDATE_NOTIFICATIONS_ENDPOINT = exports.GET_NOTIFICATIONS_ENDPOINT = exports.APPSPACE_GET_QUICK_ACCESS_ICON_URL = exports.APPSPACE_USER_PREFERENCES = exports.SWITCH_ROLE = exports.USER_PROFILE_SETTINGS_PROFILE_PICTURE = exports.USER_PROFILE_SETTINGS_RESET = exports.ALL_AVATAR_INFO = exports.USER_PROFILE_SETTINGS_CONFIG = exports.USER_PROFILE_SETTINGS = exports.BROWSER_SETTINGS = exports.USER_ROLES_ASSIGNED = exports.PROFILE_DETAILS = exports.APPLICATION_USER_PREFERENCES = exports.APPSPACE_GET_APPLICATION_DETAIL = exports.APPSPACE_GET_ALL_APPLICATIONS = exports.IDENTITY_CMDDEV_HOST = exports.IDENTITY_CMDDEV = exports.API_CMDDEV_HOST = exports.API_XPANSE = exports.LUNA_CMDDEV_HOST = exports.LOGIN = exports.BFF_PRIFIX = exports.SOURCE_APPLICATION_ID = exports.LUNA_GENERIC = exports.IDENTITY = exports.API = exports.LOCALHOST = void 0;
exports.LOCALHOST = `localhost`;
exports.API = `api`;
exports.IDENTITY = `identity`;
exports.LUNA_GENERIC = `luna-generic`;
exports.SOURCE_APPLICATION_ID = `appspacepreference`;
exports.BFF_PRIFIX = `/session-management/bff`;
exports.LOGIN = "/login";
exports.LUNA_CMDDEV_HOST = `hyperbridge-xpanse.cmddev.thermofisher.com`;
exports.API_XPANSE = `https://api.hyperbridge-xpanse.cmddev.thermofisher.com`;
exports.API_CMDDEV_HOST = `api.hyperbridge-xpanse.cmddev.thermofisher.com`;
exports.IDENTITY_CMDDEV = `https://identity.hyperbridge-xpanse.cmddev.thermofisher.com`;
exports.IDENTITY_CMDDEV_HOST = `identity.hyperbridge-xpanse.cmddev.thermofisher.com`;
///Get All applications
const APPSPACE_GET_ALL_APPLICATIONS = () => `/appspace-management/api/v2/homepage`;
exports.APPSPACE_GET_ALL_APPLICATIONS = APPSPACE_GET_ALL_APPLICATIONS;
///Get application detail by name
const APPSPACE_GET_APPLICATION_DETAIL = (applicationName) => `/appspace-management/api/v2/applications?name=${applicationName}`;
exports.APPSPACE_GET_APPLICATION_DETAIL = APPSPACE_GET_APPLICATION_DETAIL;
const APPLICATION_USER_PREFERENCES = (_applicationId, _sourceId) => `/appspace-management/api/v1/applications/${_applicationId}/${_sourceId}/userApplicationPreference`;
exports.APPLICATION_USER_PREFERENCES = APPLICATION_USER_PREFERENCES;
const PROFILE_DETAILS = (userId) => `/user-management/api/v1/user/profile/${userId}`;
exports.PROFILE_DETAILS = PROFILE_DETAILS;
const USER_ROLES_ASSIGNED = (userId) => `/user-management/api/v2/users/${userId}/roles`;
exports.USER_ROLES_ASSIGNED = USER_ROLES_ASSIGNED;
const BROWSER_SETTINGS = (userId) => `/user-management/api/v2/ProfileSettings/${userId}/browserdefaults`;
exports.BROWSER_SETTINGS = BROWSER_SETTINGS;
const USER_PROFILE_SETTINGS = (userId) => `/user-management/api/v2/ProfileSettings/${userId}`;
exports.USER_PROFILE_SETTINGS = USER_PROFILE_SETTINGS;
const USER_PROFILE_SETTINGS_CONFIG = (userId) => `/user-management/api/v2/ProfileSettings/${userId}/configuration`;
exports.USER_PROFILE_SETTINGS_CONFIG = USER_PROFILE_SETTINGS_CONFIG;
const ALL_AVATAR_INFO = () => `/user-management/api/v2/ProfileMasterData/Avatars`;
exports.ALL_AVATAR_INFO = ALL_AVATAR_INFO;
const USER_PROFILE_SETTINGS_RESET = (userId) => `/user-management/api/v2/ProfileSettings/${userId}/reset/`;
exports.USER_PROFILE_SETTINGS_RESET = USER_PROFILE_SETTINGS_RESET;
const USER_PROFILE_SETTINGS_PROFILE_PICTURE = (userId) => `/user-management/api/v2/ProfileSettings/${userId}/profilepicture`;
exports.USER_PROFILE_SETTINGS_PROFILE_PICTURE = USER_PROFILE_SETTINGS_PROFILE_PICTURE;
const SWITCH_ROLE = (roleId) => `/role-switch?roleId=${roleId}`;
exports.SWITCH_ROLE = SWITCH_ROLE;
//Get/Set Appsace platform user preferences, as whole AppSpace preferences not application or widget specific
const APPSPACE_USER_PREFERENCES = (_sourceId) => `/appspace-management/api/v1/${_sourceId}/userPreference`;
exports.APPSPACE_USER_PREFERENCES = APPSPACE_USER_PREFERENCES;
//Get application Icon URL
const APPSPACE_GET_QUICK_ACCESS_ICON_URL = (appId, quickAccessIconURL) => `/appspace-management/api/v1/applications/${appId}/${quickAccessIconURL === null || quickAccessIconURL === void 0 ? void 0 : quickAccessIconURL.toLowerCase()}`;
exports.APPSPACE_GET_QUICK_ACCESS_ICON_URL = APPSPACE_GET_QUICK_ACCESS_ICON_URL;
const GET_NOTIFICATIONS_ENDPOINT = (mailBoxAddress) => `/notification/api/v1/mailbox/${mailBoxAddress}/inbox/messages/list`;
exports.GET_NOTIFICATIONS_ENDPOINT = GET_NOTIFICATIONS_ENDPOINT;
const UPDATE_NOTIFICATIONS_ENDPOINT = (mailBoxAddress, messageId) => `/notification/api/v1/mailbox/${mailBoxAddress}/messages/${messageId}/state`;
exports.UPDATE_NOTIFICATIONS_ENDPOINT = UPDATE_NOTIFICATIONS_ENDPOINT;
const SEND_MESSAGE_ENDPOINT = (senderMailBoxAddress) => `/notification/api/v1/mailbox/${senderMailBoxAddress}/sendMessage`;
exports.SEND_MESSAGE_ENDPOINT = SEND_MESSAGE_ENDPOINT;
const DELETE_MESSAGE_ENDPOINT = (mailBoxAddress, messageId) => `/notification/api/v1/mailbox/${mailBoxAddress}/messages/${messageId}`;
exports.DELETE_MESSAGE_ENDPOINT = DELETE_MESSAGE_ENDPOINT;
const UNREAD_COUNT_ENDPOINT = (mailBoxAddress) => `/notification/api/v1/mailbox/${mailBoxAddress}/unreadCount`;
exports.UNREAD_COUNT_ENDPOINT = UNREAD_COUNT_ENDPOINT;
/* Global settings managemet */
const GLOBAL_USER_SETTINGS = (application, category) => `/globalsettings-management/api/v3/settings?application=${application}&category=${category}`;
exports.GLOBAL_USER_SETTINGS = GLOBAL_USER_SETTINGS;
exports.SUBSCRIBE_SIGNALR = "Subscribe";
exports.RECEIVED_MESSAGE = "ReceivedMessage";
exports.UNSUBSCRIBE_SIGNALR = "Unsubscribe";
exports.SIGNALR_ENDPOINT = "/realtime-message-bridge/hub";
var NotificationType;
(function (NotificationType) {
    NotificationType["Luna"] = "ThermoFisher.Luna.Notification";
    NotificationType["Toast"] = "ThermoFisher.Luna.ToastNotification";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
var NotificationFilterState;
(function (NotificationFilterState) {
    NotificationFilterState["New"] = "unread or new";
    NotificationFilterState["Read"] = "read";
    NotificationFilterState["Clear"] = "cleared";
    NotificationFilterState["Active"] = "read or unread or new";
})(NotificationFilterState = exports.NotificationFilterState || (exports.NotificationFilterState = {}));
var NotificationState;
(function (NotificationState) {
    NotificationState["Read"] = "read";
    NotificationState["Unread"] = "unread";
    NotificationState["Clear"] = "cleared";
    NotificationState["New"] = "new";
    NotificationState["Delete"] = "deleted";
})(NotificationState = exports.NotificationState || (exports.NotificationState = {}));
var NotificationFilterSeverity;
(function (NotificationFilterSeverity) {
    NotificationFilterSeverity["Error"] = "error";
    NotificationFilterSeverity["Warning"] = "warning";
    NotificationFilterSeverity["Success"] = "success";
    NotificationFilterSeverity["Info"] = "info";
})(NotificationFilterSeverity = exports.NotificationFilterSeverity || (exports.NotificationFilterSeverity = {}));
exports.FROM_POPUP = "fromPopup";
exports.HOUR12 = "12-Hour Notation";
exports.HOUR24 = "24-Hour Notation";
