"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HourNotation = exports.AccountSettingsKey = void 0;
var AccountSettingsKey;
(function (AccountSettingsKey) {
    AccountSettingsKey["ACCOUNT_INFO"] = "AccountSettings";
    AccountSettingsKey["PROFILE"] = "AccountSettings/Profile";
    AccountSettingsKey["PROFILE_FIRSTNAME"] = "AccountSettings/Profile/FirstName";
    AccountSettingsKey["PROFILE_LASTNAME"] = "AccountSettings/Profile/LastName";
    AccountSettingsKey["PROFILE_NICKNAME"] = "AccountSettings/Profile/NickName";
    AccountSettingsKey["PROFILE_PICTURE"] = "AccountSettings/Profile/ProfilePicture";
    AccountSettingsKey["PROFILE_PICTURE_AVATAR"] = "AccountSettings/Profile/ProfilePicture/Avatar";
    AccountSettingsKey["PROFILE_PICTURE_IMAGE"] = "AccountSettings/Profile/ProfilePicture/Image";
    AccountSettingsKey["PROFILE_PICTURE_TYPE"] = "AccountSettings/Profile/ProfilePicture/Type";
    AccountSettingsKey["PROFILE_PICTURE_BACKGROUND_COLOR"] = "AccountSettings/Profile/ProfilePicture/BackgroundColor";
    AccountSettingsKey["PROFILE_PICTURE_FOREGROUND_COLOR"] = "AccountSettings/Profile/ProfilePicture/ForegroundColor";
    AccountSettingsKey["LOGGEDIN_IDP"] = "AccountSettings/Profile/ProviderType";
    AccountSettingsKey["PERSONAL_INFORMATION"] = "AccountSettings/PersonalInformation";
    AccountSettingsKey["PERSONAL_INFORMATION_EMAIL"] = "AccountSettings/PersonalInformation/Email";
    AccountSettingsKey["PERSONAL_INFORMATION_PHONE"] = "AccountSettings/PersonalInformation/Phone";
    AccountSettingsKey["PERSONAL_INFORMATION_LANGUAGE"] = "AccountSettings/PersonalInformation/Language";
    AccountSettingsKey["BROWSER_SETTINGS_TOGGLE"] = "AccountSettings/BrowserDefaults/Toggle";
    AccountSettingsKey["BROWSER_DEFAULTS_LOCALE"] = "AccountSettings/BrowserDefaults/Locale";
    AccountSettingsKey["DATETIME"] = "AccountSettings/DateAndTime";
    AccountSettingsKey["DATETIME_HOUR_NOTATION"] = "AccountSettings/DateAndTime/HourNotation";
    AccountSettingsKey["DATETIME_DATE_DISPLAY"] = "AccountSettings/DateAndTime/DateDisplay";
    AccountSettingsKey["DATETIME_BROWSER_DEFAULTS_DATE_DISPLAY"] = "AccountSettings/DateAndTime/BrowserDefaults/DateDisplay";
    AccountSettingsKey["DATETIME_TIME_ZONE"] = "AccountSettings/DateAndTime/TimeZone";
    AccountSettingsKey["DATETIME_BROWSER_DEFAULTS_TIME_ZONE"] = "AccountSettings/DateAndTime/BrowserDefaults/TimeZone";
    AccountSettingsKey["FORMATTING"] = "AccountSettings/Formatting";
    AccountSettingsKey["FORMATTING_LIST_SEPARATOR"] = "AccountSettings/Formatting/ListSeparator";
    AccountSettingsKey["FORMATTING_DECIMAL_SEPARATOR"] = "AccountSettings/Formatting/DecimalSeparator";
    AccountSettingsKey["FORMATTING_BROWSER_DEFAULTS_LIST_SEPARATOR"] = "AccountSettings/Formatting/BrowserDefaults/ListSeparator";
    AccountSettingsKey["FORMATTING_BROWSER_DEFAULTS_DECIMAL_SEPARATOR"] = "AccountSettings/Formatting/BrowserDefaults/DecimalSeparator";
    AccountSettingsKey["FORMATTING_LIST_DECIMAL_SEPARATOR"] = "AccountSettings/Formatting/ListAndDecimalSeparator";
    AccountSettingsKey["FORMATTING_BROWSER_DEFAULTS_LIST_DECIMAL_SEPARATOR"] = "AccountSettings/Formatting/BrowserDefaults/ListAndDecimalSeparator";
    AccountSettingsKey["CHANGE_PWD_ARDIA"] = "AccountSettings/Profile/PasswordManagement/Ardia";
    AccountSettingsKey["CHANGE_PWD_CHROMELEON"] = "AccountSettings/Profile/PasswordManagement/Chromeleon";
})(AccountSettingsKey = exports.AccountSettingsKey || (exports.AccountSettingsKey = {}));
var HourNotation;
(function (HourNotation) {
    HourNotation["12-Hour Notation"] = "h:mm:ss a";
    HourNotation["24-Hour Notation"] = "HH:mm:ss";
})(HourNotation = exports.HourNotation || (exports.HourNotation = {}));
